import { h } from 'preact'
import { useLocales } from '~core/localisation'
import style from './style.scss'
import { useEffect, useRef } from 'preact/compat'
import { classy } from '@onfido/castor'

type SpinnerProps = {
  shouldAutoFocus?: boolean
  floating?: boolean
}

const Spinner = ({ shouldAutoFocus, floating = true }: SpinnerProps) => {
  const { translate } = useLocales()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    containerRef.current && shouldAutoFocus && containerRef.current.focus()
  }, [shouldAutoFocus])

  return (
    <div>
      <div
        className={classy(style.loader, floating ? style.floating : '')}
        aria-live="assertive"
        tabIndex={-1}
        role="progressbar" // fixes issues on iOS where the aria-live="assertive" is not announced
        ref={containerRef}
        aria-label={translate('generic.loading')}
      >
        <div className={style.inner}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  )
}

export default Spinner
