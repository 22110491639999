import { logger } from '../Logger'
import { sendEvent } from 'Tracker'

type BenchmarkWorkerResult = {
  success: boolean
  duration?: number
  error?: string
}

const buildBenchmarkWorker = (): Worker => {
  const blobURL = URL.createObjectURL(
    new Blob(
      [
        `(
${() => {
  // Use WasmFiddle to rebuild: https://wasdk.github.io/WasmFiddle/
  // and convert to Base64.
  //
  // double multiplyDouble(double a, double b, int n) {
  //     double c = 1.0;
  //     for (int i = 0; i < n; i++) {
  //       c = c * a * b;
  //     }
  //     return c;
  // }

  const wasmMultiplyDoubleBase64Code =
    'AGFzbQEAAAABiICAgAABYAN8fH8BfAOCgICAAAEABISAgIAAAXAAAAWDgICAAAEAAQaBgICAAAAHm4CAgAACBm1lbW9yeQIADm11bHRpcGx5RG91YmxlAAAKt4CAgAABsYCAgAABAXxEAAAAAAAA8D8hAwJAIAJBAUgNAANAIAMgAKIgAaIhAyACQX9qIgINAAsLIAML'

  const wasmSource = Uint8Array.from(atob(wasmMultiplyDoubleBase64Code), (c) =>
    c.charCodeAt(0)
  ).buffer
  const wasmModule = new WebAssembly.Module(wasmSource)
  const wasmInstance = new WebAssembly.Instance(wasmModule)
  const wasmMultiplyDouble = wasmInstance.exports.multiplyDouble as (
    a: number,
    b: number,
    n: number
  ) => number
  try {
    // Warm up
    wasmMultiplyDouble(2.0, 2.0, 1)

    const loops = 1000000
    const before = performance.now()
    wasmMultiplyDouble(2.0, 2.0, loops)
    const after = performance.now()

    self.postMessage({
      success: true,
      duration: after - before,
    } as BenchmarkWorkerResult)
  } catch (error) {
    if (error instanceof Error) {
      logger.warning(error.message)
      self.postMessage({
        success: false,
        error: error.name,
      } as BenchmarkWorkerResult)
    } else {
      self.postMessage({
        success: false,
      } as BenchmarkWorkerResult)
    }
  }
}}
)()`,
      ],
      { type: 'application/javascript' }
    )
  )

  const worker = new Worker(blobURL)

  URL.revokeObjectURL(blobURL)

  return worker
}

export const performanceBenchmark = async (): Promise<number> => {
  try {
    sendEvent('performance_benchmark_started')

    const worker = buildBenchmarkWorker()

    const timeout = new Promise<BenchmarkWorkerResult>((resolve) => {
      setTimeout(() => {
        worker.terminate()
        resolve({ success: false, error: 'Timeout' })
      }, 1000)
    })

    const benchmark = new Promise<BenchmarkWorkerResult>((resolve) => {
      worker.onmessage = ({ data }: MessageEvent) => {
        resolve(data as BenchmarkWorkerResult)
      }
    })

    const { success, duration, error } = await Promise.any([timeout, benchmark])

    sendEvent('performance_benchmark_completed', {
      duration_ms: duration,
      success,
      error,
    })

    return duration !== undefined ? duration : +Infinity
  } catch (error) {
    console.warn('WebAssembly performance benchmark failed', error)
    return +Infinity
  }
}
