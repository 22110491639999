import { useEffect, useState } from 'preact/hooks'

import { reduxStore } from 'components/ReduxAppWrapper'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { useSdkOptions } from '~contexts/useSdkOptions'
import { loadExternalScript } from '~utils/dynamicLoading'

// usePassiveSignals() return type
type ReturnType = PassiveSignals.Tracker | undefined

type Options = {
  token?: string
}

export default function usePassiveSignals(opts: Options = {}): ReturnType {
  const [sdkOptions] = useSdkOptions()
  const sdkConfiguration = useSdkConfigurationService()

  const [tracker, setTracker] = useState<PassiveSignals.Tracker | undefined>(
    undefined
  )

  let analyticsSessionUuid: string | undefined

  reduxStore.subscribe(() => {
    const store = reduxStore.getState()
    analyticsSessionUuid = store.globals.analyticsSessionUuid
  })

  useEffect(() => {
    const { token } = opts

    // Don't load the Passive Signals module by default
    const enabled =
      sdkConfiguration?.device_intelligence?.passive_signals?.enabled || false

    if (!enabled || !token || !process.env.PASSIVE_SIGNALS_URL) {
      return
    }

    loadExternalScript(process.env.PASSIVE_SIGNALS_URL, () => {
      if (window.OnfidoPassiveSignals) {
        const newTracker = new window.OnfidoPassiveSignals({
          jwt: token,
          context: {
            disable_cookies: sdkOptions.disableAnalyticsCookies,
            sdk_source: process.env.SDK_SOURCE,
            sdk_version: process.env.SDK_VERSION,
            sdk_analytics_session_uuid: analyticsSessionUuid,
          },
        })

        setTracker(newTracker)
      }
    })

    return () => {
      // Stop all the connected tracers
      tracker?.stop()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return tracker
}
