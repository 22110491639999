import { OutputInterface } from './types'
import { Logger as BaseLogger } from './Logger'

import { ConsoleOutput } from './outputs/ConsoleOutput'
import { NetworkOutput } from './outputs/NetworkOutput'

export const consoleOutput = new ConsoleOutput()
export const networkOutput = new NetworkOutput()
const defaultOutputs: OutputInterface[] = [consoleOutput, networkOutput]

// Loggers
export class Logger extends BaseLogger {
  constructor(props?: { labels?: string[] }) {
    super({
      outputs: defaultOutputs,
      labels: props?.labels || [],
    })
  }
}

export const logger = new Logger()
