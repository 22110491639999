import { h } from 'preact'
import { Select, Option } from '@onfido/castor-react'
import { useLocales } from '~core/localisation'
import { getIdTypesList, hasIdTypeSelector } from './idTypes'
import styles from './styles.scss'

export { hasIdTypeSelector }

export type IdTypeSelectorProps = {
  name?: string
  value?: number | string
  invalid?: boolean
  country: string | undefined
  onChange?: (ev: { target: { value: string } }) => void
}

export const IdTypeSelector = (props: IdTypeSelectorProps) => {
  const { translate } = useLocales()
  const selectIdTypesList = () => {
    return props.country ? getIdTypesList(props.country, translate) : []
  }

  return (
    <div className={styles.idTypeSelector}>
      <Select {...props} native>
        <Option hidden value="">
          {translate('profile_data.components.id_type_select.placeholder')}
        </Option>
        {selectIdTypesList().map(({ name, type }) => (
          <Option key={type} value={type}>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  )
}
