import {
  languages,
  baseLanguageMap,
  defaultLocaleTag,
  SupportedLanguages,
  rtl,
} from './languages'
import { LocaleConfig, LocaleDirection } from './types'

export const findSupportedLanguage = (language: string) => {
  const languageCode: string = language.replace(/-/g, '_')
  const splits: string[] = languageCode.split('_')

  // Match full against full. Format: en_US
  const fullMatch: boolean = Object.keys(languages).indexOf(languageCode) > -1

  if (fullMatch) {
    return languageCode
  }

  // For formats like: en-GB-oxendict
  if (splits.length === 3) {
    const medium: string = splits.slice(0, 2).join('_')
    const mediumMatch: boolean = Object.keys(languages).indexOf(medium) > -1

    if (mediumMatch) {
      return medium
    }
  }

  // Match baseLanguage against short. Format: es
  const baseLanguage: string | undefined = languages[splits[0]]
    ? splits[0]
    : baseLanguageMap[splits[0]]

  if (baseLanguage) {
    return baseLanguage
  }

  return null
}

export const deviceLanguages = navigator.languages || [navigator.language]

export const getSupportLanguage = (
  language?: SupportedLanguages | LocaleConfig
): SupportedLanguages | LocaleConfig => {
  if (typeof language === 'object' && language !== null) {
    return language
  }

  // Place the Onfido.init language as first choose
  // otherwise try to match a browser language
  const requestedLanguages = [language, ...deviceLanguages].filter(
    Boolean
  ) as string[]

  // Match the browser language with our supported languages
  for (const requestedLanguage of requestedLanguages) {
    const supportedLanguage = findSupportedLanguage(requestedLanguage)
    if (supportedLanguage) {
      return supportedLanguage as SupportedLanguages
    }
  }

  return defaultLocaleTag
}

export const getDirection = (
  language: SupportedLanguages | LocaleConfig
): LocaleDirection => {
  if (typeof language === 'object' && language?.direction) {
    return language.direction
  }

  if (typeof language === 'string' && languages[language]) {
    return rtl.indexOf(language) > -1 ? 'rtl' : 'ltr'
  }

  return 'ltr'
}
