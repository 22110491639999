import performanceAnalytics, { createSyncPerformanceEvent } from '../index'
import { Logger } from '~core/Logger'

const logger = new Logger({
  labels: ['analytics', 'performance', 'capture_screen'],
})

type Navigation = {
  eventName: 'screen_load'
  properties: never
  start_properties: {
    origin_screen: string
  }
  end_properties: {
    destination_screen: string
  }
}

const SCREEN_LOAD = createSyncPerformanceEvent<Navigation>('screen_load')

class CaptureScreenPerformance {
  private originScreen?: string

  public onScreenChangeStart() {
    if (!this.originScreen) return

    performanceAnalytics.trackStart<Navigation>(SCREEN_LOAD, {
      origin_screen: this.originScreen,
    })
  }

  public onScreenChangeEnd(destinationScreen: string) {
    if (!destinationScreen) {
      logger.error('onScreenChangeEnd - destinationScreen is not available')
      return
    }
    destinationScreen = destinationScreen.toUpperCase()

    if (destinationScreen && this.originScreen) {
      performanceAnalytics.trackEnd<Navigation>(SCREEN_LOAD, {
        destination_screen: destinationScreen,
      })
    }
    this.originScreen = destinationScreen
  }
}

export default new CaptureScreenPerformance()
