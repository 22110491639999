import { h } from 'preact'
import classNames from 'classnames'
import style from './style.scss'

type BulletListProps = {
  ariaLabel: string
  className?: string
  items: Array<{
    iconClassName?: string
    icon?: JSX.Element
    text: string
    key: string
  }>
}

export const BulletList = (props: BulletListProps) => {
  return (
    <ul
      aria-label={props.ariaLabel}
      className={classNames(style.content, style.list, props.className)}
    >
      {(props.items || []).map(({ key, icon, iconClassName, text }) => (
        <li key={key} className={style.stage}>
          <div className={classNames(style.itemIcon, iconClassName)}>
            {icon}
          </div>

          <div className={classNames(style.itemMessage)}>{text}</div>
        </li>
      ))}
    </ul>
  )
}
