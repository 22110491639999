import { h, FunctionComponent } from 'preact'
import classNames from 'classnames'

import { buildIteratorKey } from '~utils'
import { useLocales } from '~core/localisation'
import style from './style.scss'

import type { StepTypes } from '~types/steps'
import { useSdkOptions } from '~contexts/useSdkOptions'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'

type BaseProps = {
  captureSteps: StepTypes[]
}

type InstructionsProps = {
  className?: string
} & BaseProps

const Instructions: FunctionComponent<InstructionsProps> = ({
  className,
  captureSteps,
}) => {
  const { translate } = useLocales()
  const [{}, { findStep }] = useSdkOptions()
  const sdkConfig = useSdkConfigurationService()

  const getInstructionKeys = (configuredCaptureSteps: StepTypes[]) => {
    const isDocumentVideo = !!sdkConfig.experimental_features
      ?.enable_multi_frame_capture

    const isFaceVideo =
      findStep('face')?.options?.requestedVariant === 'motion' ||
      findStep('face')?.options?.requestedVariant === 'video'

    const instructionKeys: string[] = []
    const welcomeScreenLocalesMapping: Partial<Record<StepTypes, string>> = {
      poa: 'poa_welcome_text',
      document: isDocumentVideo
        ? 'welcome.list_item_doc_video'
        : 'welcome.list_item_doc_photo',
      face: isFaceVideo
        ? 'welcome.list_item_face_video'
        : 'welcome.list_item_face_photo',
      activeVideo: 'welcome.list_item_face_video',
    }

    configuredCaptureSteps.forEach((idvStep) => {
      const localeString = welcomeScreenLocalesMapping[idvStep]

      if (localeString) {
        instructionKeys.push(localeString)
      }
    })

    return instructionKeys
  }

  const instructionKeys = getInstructionKeys(captureSteps)

  return (
    <div className={classNames(style.instructions, className)}>
      <span>{translate('welcome.list_header')}</span>
      <ol>
        {instructionKeys.map((itemKey) => (
          <li key={itemKey}>{translate(itemKey)}</li>
        ))}
      </ol>
    </div>
  )
}

type DefaultContentProps = {
  descriptions?: string[]
} & BaseProps

export const DefaultContent: FunctionComponent<DefaultContentProps> = ({
  captureSteps,
  descriptions: customDescriptions,
}) => {
  if (!customDescriptions) {
    return (
      <div className={style.content}>
        <Instructions captureSteps={captureSteps} />
      </div>
    )
  }

  return (
    <div className={style.content}>
      <div className={style.customDescriptions}>
        {customDescriptions.map((description) => (
          <p key={`description_${buildIteratorKey(description)}`}>
            {description}
          </p>
        ))}
      </div>
    </div>
  )
}
