import { h } from 'preact'
import { mimeType } from '~utils/blob'
import {
  uploadDocument,
  UploadDocumentPayload,
  UploadVideoPayload,
  UploadDocumentAnalyticsPayload,
  DocumentAutoCaptureAnalytics,
} from '~utils/onfidoApi'
import { CapturePayload, DocumentCapture, FaceCapture } from '~types/redux'
import { v4 as uuidv4 } from 'uuid'

import { useEffect, useState } from 'preact/hooks'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { CaptureFormat, CaptureMethodRendered } from '~types/tracker'
import { buildStepFinder } from '~utils/steps'
import { shouldUseCameraForDocumentCapture } from '~utils/shouldUseCamera'
import {
  getGenericDocumentAnalytics,
  trackSuccessfulDocumentUpload,
} from 'Tracker/documentTracking'
import { getIqsValidationRequestPayload, uploadMFCVideo } from './utils'
import { documentAutoConfiguration } from 'components/DocumentAuto/utils'
import {
  CallbackNames,
  ConfirmProps,
  OnApiErrorCallback,
  OnApiSuccessCallback,
  OnSubmitCallback,
  useConfirm,
} from './useConfirm'
import Previews from './Previews'
import Spinner from 'components/Spinner'

export type DocumentConfirmProps = Omit<
  ConfirmProps,
  'upload' | 'submitUpload' | 'method'
>

export const DocumentConfirm = (props: DocumentConfirmProps) => {
  // Since we send only one analytics event for one confirm, we don't need to change imageUUID
  const [imageUUID] = useState<string>(uuidv4())
  const sdkConfiguration = useSdkConfigurationService()
  const {
    isAutoCapture,
    isMultiFrameCapture,
    maxRetryCount,
  } = documentAutoConfiguration(
    sdkConfiguration,
    props.documentType,
    props.idDocumentIssuingCountry
  )

  const buildUploadDocumentAnalyticsPayload = ():
    | UploadDocumentAnalyticsPayload
    | undefined => {
    if (props.currentStepType !== 'document') {
      return
    }
    const findStep = buildStepFinder(props.steps)
    const doc = findStep('document')
    let capture_format: CaptureFormat | undefined
    if (doc?.options?.requestedVariant) {
      capture_format =
        doc?.options?.requestedVariant === 'standard' ? 'photo' : 'camera'
    }
    const capture_method_rendered: CaptureMethodRendered = shouldUseCameraForDocumentCapture(
      sdkConfiguration,
      props.deviceHasCameraSupport
    )
      ? 'camera'
      : 'upload'

    return {
      document_side: props.side,
      country_code: props.idDocumentIssuingCountry?.country_alpha3,
      document_type: props.documentType,
      count_attempt: props.imageQualityRetries,
      max_retry_count: maxRetryCount,
      image_uuid: imageUUID,
      capture_method_rendered,
      capture_format,
      ...getDocumentAutocaptureAnalytics(),
      ...getGenericDocumentAnalytics(props),
    }
  }
  const getDocumentAutocaptureAnalytics = (): DocumentAutoCaptureAnalytics => {
    return {
      is_autocapture_used: !!props.capture.isAutoCaptureUsed,
      is_autocapture_enabled: !!isAutoCapture,
      is_multiframe_capture: !!isMultiFrameCapture,
    }
  }

  useEffect(() => {
    const trackData = {
      document_type: props.documentType,
      country_code: props.idDocumentIssuingCountry?.country_alpha2,
      count_attempt: props.imageQualityRetries,
      max_retry_count: maxRetryCount,
      warning_origin: 'device',
      image_uuid: imageUUID,
      warnings: undefined, // leave this empty to show that there are no on device warning.
    }
    // 'DOCUMENT_CONFIRMATION'
    props.trackScreen(undefined, {
      ...trackData,
      ...getDocumentAutocaptureAnalytics(),
      ...getGenericDocumentAnalytics(props),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getIssuingCountry = () => {
    const { idDocumentIssuingCountry } = props

    if (idDocumentIssuingCountry && idDocumentIssuingCountry.country_alpha3) {
      return { issuing_country: idDocumentIssuingCountry.country_alpha3 }
    }
    return {}
  }

  const uploadDocumentToOnfido = (
    capture: CapturePayload,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback,
    onSubmitCallback: OnSubmitCallback
  ) => {
    const { urls, side, token, imageQualityRetries, isDecoupledFromAPI } = props

    if (!token) {
      throw new Error('token not provided')
    }

    const url = urls.onfido_api_url
    const { blob, filename, sdkMetadata } = capture

    const issuingCountry = getIssuingCountry()

    // Make sure documents always have a filename
    // A `filename` might have been defined when the capture is created
    // if filename is not present, check if `blob` has a property `name` (only available for File types, which come from the html5 file picker)
    // alternatively use default filename
    const blobName =
      filename || (blob as File).name || `document_capture.${mimeType(blob)}`
    const { documentType: type } = capture as DocumentCapture
    const data: UploadDocumentPayload = {
      file: { blob, filename: blobName },
      type: type === 'generic_document' ? 'unknown' : type,
      side,
      validations: getIqsValidationRequestPayload(
        maxRetryCount,
        imageQualityRetries,
        blob,
        props.documentType,
        side,
        props.idDocumentIssuingCountry
      ),
      sdkMetadata: { ...sdkMetadata, image_uuid: imageUUID },
      ...issuingCountry,
    }
    if (isDecoupledFromAPI) {
      onSubmitCallback(data, 'onSubmitDocument')
    } else {
      uploadDocument(
        data,
        url,
        token,
        undefined,
        undefined,
        buildUploadDocumentAnalyticsPayload()
      )
        .then((res) => {
          trackSuccessfulDocumentUpload(
            props.documentType,
            props.side,
            props.idDocumentIssuingCountry,
            props.trackScreen,
            getDocumentAutocaptureAnalytics(),
            getGenericDocumentAnalytics(props)
          )
          // Multi Frame Capture video
          if (props.captures.document_video) {
            return uploadMFCVideo(
              props.captures.document_video,
              res,
              url,
              token
            )
          }

          return res
        })
        .then(onApiSuccess)
        .catch(onApiError)
    }
  }

  const submitUpload = (
    data: FaceCapture | UploadVideoPayload | UploadDocumentPayload,
    callbackName: CallbackNames,
    url: string | undefined,
    token: string,
    onApiSuccess: OnApiSuccessCallback,
    onApiError: OnApiErrorCallback
  ) => {
    uploadDocument(
      data as UploadDocumentPayload,
      url,
      token,
      onApiSuccess,
      onApiError,
      buildUploadDocumentAnalyticsPayload()
    )
  }

  const {
    actions,
    capture,
    completeStep,
    crossDeviceClientError,
    enterpriseFeatures,
    nextStep,
    previousStep,
    resetSdkFocus,
    token,
    trackScreen,
    triggerOnError,
    urls,
  } = props

  const { uploadInProgress, confirmError, onConfirm, onRetake } = useConfirm(
    actions,
    capture,
    'document',
    token,
    urls,
    completeStep,
    nextStep,
    previousStep,
    resetSdkFocus,
    submitUpload,
    trackScreen,
    triggerOnError,
    uploadDocumentToOnfido,
    crossDeviceClientError,
    enterpriseFeatures,
    getDocumentAutocaptureAnalytics(),
    {
      document_type: props.documentType,
      country_code: props.idDocumentIssuingCountry?.country_alpha2,
      warning_origin: 'remote',
      count_attempt: props.imageQualityRetries,
      max_retry_count: maxRetryCount,
      // not sure what is_blocking refers to, but its the correct way to compute it
      is_blocking: props.imageQualityRetries > maxRetryCount,
      ...getDocumentAutocaptureAnalytics(),
    }
  )

  if (uploadInProgress) {
    return <Spinner />
  }

  return (
    <Previews
      isFullScreen={!!props.isFullScreen}
      capture={props.capture}
      retakeAction={onRetake}
      confirmAction={onConfirm}
      isUploading={uploadInProgress}
      error={confirmError}
      trackScreen={props.trackScreen}
      method={'document'}
      documentType={props.documentType}
      forceRetake={confirmError?.type === 'error'}
    />
  )
}

export default DocumentConfirm
