import { useEffect } from 'preact/hooks'
import { sendEvent } from 'Tracker'
import { DeviceCapabilities, getDeviceCapabilities } from './deviceCapabilities'

let deviceCapabilities: DeviceCapabilities | undefined
let triggered = false

const useDeviceCapabilities = (): DeviceCapabilities | undefined => {
  useEffect(() => {
    if (!triggered) {
      triggered = true
      getDeviceCapabilities().then((capabilities) => {
        deviceCapabilities = capabilities
        sendEvent(
          'device_capabilities',
          capabilities as Record<string, unknown>
        )
      })
    }
  })

  return deviceCapabilities
}

export default useDeviceCapabilities
