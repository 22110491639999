import * as Polyglot from 'node-polyglot'
import { isDesktop } from '~utils'
import { LocaleConfig, SupportedLanguages } from './types'
import { useEffect, useState } from 'preact/hooks'
import { trackException } from 'Tracker'
import { languages, defaultLocaleTag, defaultLanguage } from './languages'

interface LocaleTypeObj {
  [key: string]: Record<string, unknown>
}

type TranslationType = Record<string, LocaleTypeObj>

// Note: We're extending because we're using private Polyglot vars
class PolyglotExtended extends Polyglot {
  phrases?: unknown
  currentLocale: SupportedLanguages | LocaleConfig['locale'] = defaultLocaleTag
}

export const createDefaultPolyglot = (): PolyglotExtended => {
  const polyglot = new Polyglot({
    onMissingKey: (key) => {
      // The empty onMissingKey hides missing keys instead of being display in front-end
      if (process.env.NODE_ENV === 'development') {
        console.error(`The locale ${key} is missing`)
      }
      return ''
    },
  }) as PolyglotExtended

  return extendPolyglot(
    polyglot,
    defaultLanguage,
    defaultLanguage?.mobilePhrases,
    'en_US'
  )
}

export const updatePolyglot = async (
  polyglot: PolyglotExtended,
  language?: SupportedLanguages | LocaleConfig
) => {
  if (!language) {
    return polyglot
  }

  const nonDefaultLocale =
    typeof language === 'string' ? language : language.locale

  if (nonDefaultLocale && languages[nonDefaultLocale]) {
    await loadSupportedLanguage(nonDefaultLocale, polyglot)
  }

  if (typeof language !== 'string') {
    await withCustomLanguage(language, polyglot)
  }

  return polyglot
}

const findMissingKeys = (
  defaultKeys: string[],
  customKeys: string[],
  customLocale: LocaleConfig['locale']
): void => {
  const newTranslationsSet = new Set(customKeys)
  const missingKeys = defaultKeys.filter(
    (element) => !newTranslationsSet.has(element)
  )
  const isSupportedLanguage = Object.keys(languages).some(
    (supportedLanguage) => supportedLanguage === customLocale
  )

  if (missingKeys.length && !isSupportedLanguage) {
    console.warn('Missing keys:', missingKeys)
  }
}

const polyglotFormatKeys = (phrases: TranslationType): string[] => {
  const polyglot = new Polyglot({ phrases }) as PolyglotExtended
  return getPhrasesFromPolyglot(polyglot)
}

const getPhrasesFromPolyglot = (polyglot: PolyglotExtended): string[] => {
  return polyglot?.phrases ? Object.keys(polyglot?.phrases as object) : []
}

const verifyKeysPresence = (
  customLanguageConfig: LocaleConfig,
  polyglot: PolyglotExtended
) => {
  const { phrases, mobilePhrases } = customLanguageConfig
  const defaultKeys = getPhrasesFromPolyglot(polyglot)
  // Currently mobilePhrases can be passed inside the phrases object or as a separate object.
  // Only return the warning for missing keys if mobilePhrases are not present in phrases or as a separate object.
  const customMobilePhrases = Object.assign(
    {},
    phrases?.mobilePhrases,
    mobilePhrases
  )
  const customKeys = polyglotFormatKeys({
    ...phrases,
    mobilePhrases: customMobilePhrases as TranslationType,
  })
  findMissingKeys(defaultKeys, customKeys, customLanguageConfig?.locale)
}

const withCustomLanguage = async (
  customLanguageConfig: LocaleConfig,
  polyglot: PolyglotExtended
): Promise<PolyglotExtended> => {
  const { locale, phrases, mobilePhrases } = customLanguageConfig
  verifyKeysPresence(customLanguageConfig, polyglot)
  return extendPolyglot(polyglot, phrases, mobilePhrases, locale)
}

const loadSupportedLanguage = async (
  language: SupportedLanguages,
  polyglot: PolyglotExtended
): Promise<PolyglotExtended | undefined> => {
  if (!languages[language]) {
    return polyglot
  }

  let translations

  try {
    translations = await languages[language]()
  } catch (e) {
    console.error(e)
    trackException(`Could not load locale file: ${language}`)
    return polyglot
  }

  return extendPolyglot(
    polyglot,
    translations,
    translations?.mobilePhrases,
    language
  )
}

const extendPolyglot = (
  polyglot: PolyglotExtended,
  phrases: LocaleConfig['phrases'],
  mobilePhrases: LocaleConfig['mobilePhrases'],
  locale?: SupportedLanguages
): PolyglotExtended => {
  polyglot.locale(locale)
  polyglot.extend(phrases)
  if (!isDesktop) {
    polyglot.extend(mobilePhrases)
  }
  return polyglot
}
