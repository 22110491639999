import { h, Component, ComponentChildren } from 'preact'
import classNames from 'classnames'
import style from './style.scss'
import { ForwardedRef, forwardRef } from 'preact/compat'

type CustomFileInputProps = {
  children: ComponentChildren
  className?: string
  accept?: string
  capture?: boolean | string
  onClick?: () => void
  onChange: (file: File) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

class CustomFileInputBase extends Component<CustomFileInputProps> {
  static defaultProps = {
    children: null,
    className: '',
    onClick: noop,
    onChange: noop,
  }

  input: HTMLInputElement | null = null

  handleClick = () => {
    if (this.input) {
      this.input.click()
    }
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  handleChange = (event: Event) => {
    if (this.input && this.input.files) {
      this.props.onChange(this.input.files[0])
    }

    if (event.currentTarget) {
      const inputTarget = event.currentTarget as HTMLInputElement
      inputTarget.value = '' // Allow re-uploading the same file
    }
  }

  render = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, className, onChange, innerRef, ...other } = this.props
    return (
      <span
        onClick={this.handleClick}
        className={classNames(style.container, className)}
      >
        {children}
        <input
          type="file"
          className={style.input}
          ref={(ref) => {
            this.input = ref

            if (typeof innerRef === 'function') {
              innerRef(ref)
            } else if (innerRef) {
              innerRef.current = ref
            }
          }}
          onChange={this.handleChange}
          {...other}
        />
      </span>
    )
  }
}

export default forwardRef<HTMLInputElement, CustomFileInputProps>(
  (props, ref) => {
    return <CustomFileInputBase {...props} innerRef={ref} />
  }
)
