export const backCameraKeywords = [
  'rear',
  'back',
  'world',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
]

export const getVideoConstraints = (
  width?: ConstrainULong,
  height?: ConstrainULong,
  facingMode?: VideoFacingModeEnum,
  audio?: boolean
): MediaStreamConstraints['video'] => {
  /*
     Safari 11 has a bug where if you specify both the height and width
     constraints you must chose a resolution supported by the web cam. If an
     unsupported resolution is used getUserMedia(constraints) will hit a
     OverconstrainedError complaining that width is an invalid constraint.
     This bug exists for ideal constraints as well as min and max.
     However if only a height is specified safari will correctly chose the
     nearest resolution supported by the web cam.
     Reference: https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints
     */
  // if `{facingMode: 'user'}` Firefox will still allow the user to choose which camera to use (Front camera will be the first option)
  // if `{facingMode: {exact: 'user'}}` Firefox won't give the user a choice and will show the front camera
  const constraints: MediaStreamConstraints = {
    video: { facingMode },
    audio,
  }
  if (width) {
    // @ts-ignore
    constraints.video.width = parseInt(width, 10) || width // some devices need a Number type
  }
  if (height) {
    // @ts-ignore
    constraints.video.height = parseInt(height, 10) || height
  }
  return constraints as MediaStreamConstraints['video']
}

export const getMirrored = (
  videoConstraints: MediaStreamConstraints['video']
): boolean => {
  if (!videoConstraints || typeof videoConstraints === 'boolean') {
    return true
  }
  return (
    videoConstraints.facingMode === 'user' ||
    /* #HACK desktop cameras seem to have `facingMode` undefined,
                   therefore we are assuming all desktop cameras are user facing*/
    !videoConstraints.facingMode
  )
}
