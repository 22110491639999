import Webcam from 'react-webcam'
import { canvasToBlob, MimeType, mimeType } from '~utils/blob'
import { CountryData, DocumentSides, SdkMetadata } from '~types/commons'
import { CapturePayload } from '~types/redux'
import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { DocumentTypes } from '~types/steps'

/*
 * There's some duplicated code because we have 2 different react-webcam
 */

export const debugVideo = (webcam: Webcam) => {
  const { stream, video } = webcam

  if (!video || !stream) {
    return
  }

  const track = stream.getVideoTracks()[0]

  const { width, height } = track.getConstraints()
  const { offsetWidth, offsetHeight, videoWidth, videoHeight } = video

  return {
    constraintsSize: [width || 0, height || 0],
    contentSize: [videoWidth, videoHeight],
    elementSize: [offsetWidth, offsetHeight],
  }
}

export const getImageData = (webcam: Webcam | null) => {
  const canvas = webcam && webcam.getCanvas()
  const video = webcam && webcam.video

  return canvas
    ?.getContext('2d', { willReadFrequently: true })
    ?.getImageData(0, 0, video?.videoWidth || 0, video?.videoHeight || 0)
}

export const imageDataToBlob = (
  imageData: ImageData,
  type?: MimeType,
  quality?: number
) => {
  const canvas = document.createElement('canvas')
  putImageData(imageData, canvas)
  return new Promise<Blob>((resolve) => {
    canvasToBlob(canvas, resolve, type, quality)
  })
}

export const putImageData = (
  imageData: ImageData,
  canvas: HTMLCanvasElement | OffscreenCanvas
) => {
  canvas.width = imageData.width
  canvas.height = imageData.height
  canvas
    .getContext('2d', { willReadFrequently: true })
    ?.putImageData(imageData, 0, 0)
}

export const getDeviceInfo = (
  stream: MediaStream | undefined | null
): SdkMetadata => {
  if (stream) {
    const videoTrack = stream.getVideoTracks()[0]
    const videoSettings = videoTrack.getSettings()
    const audioTrack = stream.getAudioTracks()[0]

    return {
      captureMethod: 'live',
      camera_name: videoTrack?.label,
      microphone_name: audioTrack?.label,
      camera_settings: {
        aspect_ratio: videoSettings?.aspectRatio,
        frame_rate: videoSettings?.frameRate,
        height: videoSettings?.height,
        width: videoSettings?.width,
      },
    }
  }

  return {}
}

export const scalePlaceholderToVideo = (
  container: DOMRect,
  video: HTMLVideoElement,
  frame: DOMRect
) => {
  const scale = video?.videoWidth / container.width
  const width = scale * frame.width
  const height = scale * frame.height

  const center_x = video?.videoWidth / 2
  const center_y = video?.videoHeight / 2

  return {
    left: center_x - width / 2,
    right: center_x + width / 2,
    bottom: center_y + height / 2,
    top: center_y - height / 2,
  }
}

export const appendFileName = (
  payload: CapturePayload,
  side: DocumentSides
): CapturePayload => ({
  ...payload,
  filename: `document_${side}.${mimeType(payload.blob)}`,
})

export const documentAutoConfiguration = (
  sdkConfiguration: SdkConfiguration,
  documentType: DocumentTypes | undefined,
  countryData: CountryData | undefined
) => {
  const isVideoCompatible = window.MediaRecorder != null

  const isMultiFrameCapture =
    !!sdkConfiguration.experimental_features?.enable_multi_frame_capture &&
    isVideoCompatible

  const {
    enable_auto_capture_doc_capture = false,
    auto_capture_enabled_documents = undefined,
    auto_capture_timeout_ms = 15000,
    max_total_retries = 4,
  } = sdkConfiguration.document_capture || {}

  const isAutoCapture =
    !!enable_auto_capture_doc_capture &&
    (!auto_capture_enabled_documents ||
      auto_capture_enabled_documents.some(
        ({ document_type, country }) =>
          document_type === documentType &&
          (!country || country === countryData?.country_alpha3)
      ))

  return {
    isAutoCapture,
    isMultiFrameCapture,
    maxRetryCount: max_total_retries,
    autoCaptureTimeoutMilliseconds: auto_capture_timeout_ms,
  }
}
