import { useCallback, useEffect, useRef } from 'preact/hooks'

const webmMimeTypes = [
  'video/webm;codecs=vp9',
  'video/webm;codecs:vp9',
  'video/webm',
  'video/webm;codecs=vp8,opus',
  'video/webm;codecs=vp8',
]

const getSupportedFormat = () => {
  const mimeTypes: string[] = [...webmMimeTypes, 'video/mp4']

  return mimeTypes.find((mimeType) =>
    window.MediaRecorder?.isTypeSupported(mimeType)
  )
}

const isWebmFormatSupported = () => {
  return webmMimeTypes.some((mimeType) =>
    window.MediaRecorder?.isTypeSupported(mimeType)
  )
}

type MediaRecorderHook = {
  startCapture: () => void
  stopCapture: () => Promise<Blob | null>
}

export const useMediaRecorder = (
  stream: MediaStream | null
): MediaRecorderHook => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)

  const startCapture = useCallback(() => {
    if (!mediaRecorderRef.current) return

    mediaRecorderRef.current.ondataavailable = null

    if (mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop()
    }

    mediaRecorderRef.current.start()
  }, [])

  const stopCapture = useCallback((): Promise<Blob | null> => {
    if (!mediaRecorderRef.current) return Promise.resolve(null)

    if (mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop()

      return new Promise((resolve) => {
        if (!mediaRecorderRef.current) return resolve(null)

        mediaRecorderRef.current.ondataavailable = ({ data }: BlobEvent) => {
          if (data.size > 0) {
            resolve(
              new Blob([data], {
                type: isWebmFormatSupported() ? 'video/webm' : 'video/mp4',
              })
            )
          } else {
            resolve(null)
          }
        }
      })
    }

    return Promise.resolve(null)
  }, [])

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new ReferenceError(
        'MediaRecorder is not supported in this browser.'
      )
    }

    if (!stream) return

    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: getSupportedFormat(),
      audioBitsPerSecond: 128000, // Default values according to specs, but Safari doesn't
      videoBitsPerSecond: 2500000, // respect them so we need to pass them
    })
  }, [stream])

  return { startCapture, stopCapture }
}
