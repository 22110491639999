import { getWebGL } from '~core/DeviceCapabilities/deviceCapabilities'

type FaceDetectionConfiguration = {
  backend: 'wasm' | 'webgl'
  runtime: 'mediapipe' | 'tfjs'
  workerEnabled: boolean
}

export const getFaceDetectionConfiguration = (): FaceDetectionConfiguration => {
  if (window.OffscreenCanvas !== undefined) {
    return { backend: 'wasm', runtime: 'tfjs', workerEnabled: true }
  }

  const support = getWebGL()

  if (!support.webgl_support || !support.has_texture_float_extension) {
    return { backend: 'wasm', runtime: 'tfjs', workerEnabled: false }
  }

  const gpu = support.gpu_model?.toLowerCase()

  // Adreno 3xx have messy WebGL support. Face Mesh was monkey-patched to support it, but we need to explicitly request the TFJS runtime.
  if (gpu?.includes('adreno') && /3[0-9]{2}/.test(gpu)) {
    return { backend: 'webgl', runtime: 'tfjs', workerEnabled: false }
  }

  if (support.webgl2_support) {
    return { backend: 'webgl', runtime: 'mediapipe', workerEnabled: false }
  }

  return { backend: 'wasm', runtime: 'tfjs', workerEnabled: false }
}

let preloadingModels = false

const mediapipeWebgl = [
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/face_mesh/face_mesh_solution_packed_assets_loader.js`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/face_mesh/face_mesh_solution_simd_wasm_bin.js`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/face_mesh/face_mesh.binarypb`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/face_mesh/face_mesh_solution_packed_assets.data`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/face_mesh/face_mesh_solution_simd_wasm_bin.wasm`,
]

const tfjsWebgl = [
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/tfjs-model/face_landmarks_detection/face_mesh/1/model.json`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/tfjs-model/face_landmarks_detection/face_mesh/1/group1-shard1of1.bin`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/tfjs-model/face_detection/short/1/model.json`,
  `${process.env.ACTIVE_VIDEO_LIVENESS}/models/@mediapipe/tfjs-model/face_detection/short/1/group1-shard1of1.bin`,
]

const tfjsWasm = [
  `${process.env.ACTIVE_VIDEO_LIVENESS}/backends/tfjs-backend-wasm/tfjs-backend-wasm-simd.wasm`,
  ...tfjsWebgl,
]

const fetchUrls = (urls: string[]) => Promise.all(urls.map((url) => fetch(url)))

export const preloadModels = () => {
  if (preloadingModels) return

  preloadingModels = true

  const { backend, runtime } = getFaceDetectionConfiguration()

  if (backend === 'webgl' && runtime === 'mediapipe') {
    fetchUrls(mediapipeWebgl)
  } else if (backend === 'webgl' && runtime === 'tfjs') {
    fetchUrls(tfjsWebgl)
  } else if (backend === 'wasm' && runtime === 'tfjs') {
    fetchUrls(tfjsWasm)
  }
}
