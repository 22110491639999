import { SdkConfiguration } from '~core/SdkConfiguration/types'
import { isDesktop } from '~utils'

export const shouldUseCameraForDocumentCapture = (
  sdkConfiguration: SdkConfiguration,
  deviceHasCameraSupport?: boolean
): boolean => {
  const canUseJsCamera =
    !isDesktop &&
    !!sdkConfiguration.document_capture?.enable_js_camera_doc_capture

  return canUseJsCamera && deviceHasCameraSupport === true
}
