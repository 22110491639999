import { h, ComponentChildren, createContext, Fragment } from 'preact'
import { useCallback, useContext, useEffect, useState } from 'preact/hooks'
import { SupportedDocuments } from '~types/api'
import { getSupportedDocuments } from '~utils/onfidoApi'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { documentSelectionType } from '~types/commons'
import {
  documentTypeMap,
  getCountryFilter,
  getDocumentStepConfig,
} from '../supported-documents'
import { StepConfig, StepConfigDocument } from '~types/steps'

type SupportedDocumentsProviderProps = {
  children: ComponentChildren
  hasWorkflow: boolean
  url?: string
  token?: string
  fallback?: ComponentChildren
  steps: StepConfig[]
}

export type SupportedDocumentsContextValue = {
  supportedDocumentsAdapter: (steps: StepConfig[]) => StepConfig[]
}

export const SupportedDocumentsContext = createContext<SupportedDocumentsContextValue>(
  {
    supportedDocumentsAdapter: () => [],
  }
)

export const SupportedDocumentsProvider = ({
  children,
  url,
  token,
  fallback,
  hasWorkflow,
  steps,
}: SupportedDocumentsProviderProps) => {
  const [documents, setDocuments] = useState<SupportedDocuments[]>([])

  const { sdk_features } = useSdkConfigurationService()
  const {
    documentStepIndex,
    documentStep,
    documentTypes,
  } = getDocumentStepConfig(steps)

  if (!token) {
    throw new Error('token not provided')
  }

  if (!url) {
    throw new Error('url not provided')
  }

  const supportedDocumentsAdapter = useCallback(
    (currentSteps: StepConfig[]) => {
      if (documents.length === 0) {
        return currentSteps
      }
      // make sure there is a documents step, since the documents are an empty array by default
      if (documentStepIndex < 0) {
        return currentSteps
      }

      const documentSelection = documents.map((document) => {
        return {
          document_type: documentTypeMap[document.document_type],
          issuing_country: document.country,
          id: '',
          config: {},
        }
      })

      return [
        ...currentSteps.slice(0, documentStepIndex),
        {
          ...documentStep,
          options: {
            ...documentStep.options,
            documentSelection,
            countryFilter: getCountryFilter(documentSelection),
          },
        },
        ...currentSteps.slice(documentStepIndex + 1),
      ]
    },
    [documents]
  )

  useEffect(() => {
    if (!url || !token) {
      setDocuments([])
      return
    }

    if (
      sdk_features?.enable_document_support_rules &&
      !hasWorkflow &&
      Object.keys(documentTypes || {}).length === 0
    ) {
      getSupportedDocuments(url, token)
        .then((response) => {
          setDocuments(response.supported_documents)
        })
        .catch(() => setDocuments([]))
    }
  }, [url, token])

  if (!documents) {
    return <Fragment>{fallback}</Fragment>
  }

  return (
    <SupportedDocumentsContext.Provider
      value={{
        supportedDocumentsAdapter,
      }}
    >
      {children}
    </SupportedDocumentsContext.Provider>
  )
}

const useSupportedDocuments = () => {
  return useContext(SupportedDocumentsContext)
}

export default useSupportedDocuments
