import { h, FunctionComponent } from 'preact'
import { Header } from '../Header'
import { CameraIcon } from '../assets/CameraIcon'
import { localised } from '~core/localisation'
import { StepComponentProps } from '~types/routers'
import type { WithLocalisedProps } from '~core/localisation/types'
import { WithTrackingProps } from '~types/hocs'
import { trackComponent } from 'Tracker'
import classNames from 'classnames'
import theme from '../../Theme/style.scss'
import { Button } from '@onfido/castor-react'
import ScreenLayout from 'components/Theme/ScreenLayout'
import styles from './style.module.scss'

type Props = StepComponentProps & WithLocalisedProps & WithTrackingProps

const RecordingComplete: FunctionComponent<Props> = ({
  nextStep,
  translate,
  trackScreen,
}: Props) => {
  const actions = (
    <Button
      type="button"
      variant="primary"
      className={classNames(theme['button-centered'], theme['button-lg'])}
      onClick={() => {
        trackScreen('outro_upload_clicked')
        nextStep()
      }}
    >
      {translate('avc_confirmation.button_primary_upload')}
    </Button>
  )

  return (
    <ScreenLayout
      actions={actions}
      className={styles.wrapperBody}
      pageId={'RecordingComplete'}
    >
      <Header
        title={translate('avc_confirmation.title')}
        subtitle={translate('avc_confirmation.subtitle')}
      >
        <CameraIcon />
      </Header>
    </ScreenLayout>
  )
}

export default trackComponent(localised(RecordingComplete), 'outro')
