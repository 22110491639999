import type { LogData, EnvironmentType, OutputInterface } from '../types'

export class ConsoleOutput implements OutputInterface {
  private log(data: LogData) {
    const labels = data.labels.join('.')
    const timestamp = data.timestamp.split('T')[1]
    const origin = data?.file && `/${data.file}:${data.line}`

    const log = [
      `${timestamp} | [${labels}] | ${data.level} - ${data.message}`,
      data.metadata,
      origin,
    ].filter(Boolean)

    if (data.level === 'fatal' || data.level === 'error') {
      console.error(...log)
    } else if (data.level === 'warning') {
      console.warn(...log)
    } else {
      console.log(...log)
    }
  }

  public write(data: LogData, environment?: EnvironmentType) {
    if (environment !== 'production') {
      this.log(data)
      return
    }

    // Only show message in production to prevent information from leaking
    if (environment === 'production' && data.level === 'fatal') {
      console.error(data.message)
    }
  }
}
