export interface DeviceCapabilities {
  webgl2_support?: boolean
  webgl_support?: boolean
  has_texture_float_extension?: boolean
  wasm_support?: boolean
  gpu_model?: string
}

export const getWebGL = () => {
  const canvas = document.createElement('canvas')
  let webGL2Support = false
  let webGLSupport = false
  let textureFloatExtension = false
  let renderer: string | undefined

  try {
    if (canvas) {
      let gl: WebGL2RenderingContext | WebGLRenderingContext | null

      gl = canvas.getContext('webgl2')

      if (gl) {
        webGL2Support = true
        textureFloatExtension = true
      } else {
        gl =
          canvas.getContext('webgl') ||
          (canvas.getContext('experimental-webgl') as WebGLRenderingContext)
      }

      if (gl) {
        webGLSupport = true

        const extensions = gl.getSupportedExtensions() || []

        const parameterName = extensions.includes('WEBGL_debug_renderer_info')
          ? gl.getExtension('WEBGL_debug_renderer_info')
              ?.UNMASKED_RENDERER_WEBGL
          : undefined

        if (parameterName) {
          renderer = gl.getParameter(parameterName)
        }

        if (!textureFloatExtension) {
          textureFloatExtension = extensions.includes('OES_texture_float')
        }
      }
    }
  } catch (e) {}

  return {
    webgl2_support: webGL2Support,
    webgl_support: webGLSupport,
    has_texture_float_extension: textureFloatExtension,
    gpu_model: renderer,
  }
}

const getWebAssembly = (): Partial<DeviceCapabilities> => {
  let webAssemblySupport = false

  try {
    if (
      typeof WebAssembly === 'object' &&
      typeof WebAssembly.instantiate === 'function'
    ) {
      const module = new WebAssembly.Module(
        Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
      )
      if (module instanceof WebAssembly.Module) {
        webAssemblySupport =
          new WebAssembly.Instance(module) instanceof WebAssembly.Instance
      }
    }
  } catch (e) {}

  return {
    wasm_support: webAssemblySupport,
  }
}

export const getDeviceCapabilities = (): Promise<DeviceCapabilities> => {
  const webGL = getWebGL()
  const webAssembly = getWebAssembly()

  return Promise.resolve({
    ...webGL,
    ...webAssembly,
  })
}
