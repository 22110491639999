import {
  DocumentTypes,
  StepOptionDocument,
  StepOptionDocumentType,
} from '~types/steps'
import { filterDocumentSelection } from './useDocumentTypesAdapter'

// This is a weird requirement, we dont know why it's there but we're afraid to change it so we're keeping it.
const shouldSkipSelectionStepIfSingleDocument = (
  documentTypes: StepOptionDocumentType
) => {
  const documentTypesKeys = Object.keys(documentTypes) as DocumentTypes[]
  let documentTypeCount = 0
  documentTypesKeys.forEach((type) => {
    if (documentTypes[type]) {
      documentTypeCount++
    }
  })
  return documentTypeCount === 1
}

export const showRestrictedDocumentSelectionStep = (
  options: StepOptionDocument | undefined
): boolean => {
  // Cover specific case for Classic configuration
  if (
    options?.documentTypes &&
    shouldSkipSelectionStepIfSingleDocument(options.documentTypes)
  ) {
    return false
  }

  if (options?.documentSelection) {
    // we get a single country, and a single document type.
    return options.documentSelection.length !== 1
  }

  return true
}
