import { ComponentType, h } from 'preact'
import { Button } from '@onfido/castor-react'
import classNames from 'classnames'
import { localised } from '~core/localisation'
import { TrackScreenCallback } from '~types/hocs'
import type {
  TranslateCallback,
  WithLocalisedProps,
} from '~core/localisation/types'
import { StepComponentFaceProps, StepComponentProps } from '~types/routers'
import { appendToTracking, trackComponent } from '../../Tracker'
import withCrossDeviceWhenNoCamera from '../Capture/withCrossDeviceWhenNoCamera'
import PageTitle from '../PageTitle'
import ScreenLayout from '../Theme/ScreenLayout'
import theme from '../Theme/style.scss'
import style from './style.scss'
import { BulletList } from 'components/BulletList'

type Instruction = { key: string; text: string }
type IntroProps = WithLocalisedProps & StepComponentFaceProps

const InstructionsPure = ({
  listScreenReaderText,
  instructions,
}: {
  listScreenReaderText: string
  instructions: Instruction[]
}) => (
  <div className={theme.scrollableContent}>
    <BulletList
      ariaLabel={listScreenReaderText}
      className={style.introBullets}
      items={instructions.map(({ key, text }) => ({
        key,
        text,
        iconClassName: style[`${key}Icon`],
      }))}
    />
  </div>
)

const Actions = ({
  nextStep,
  trackScreen,
  translate,
}: {
  nextStep: () => void
  trackScreen: TrackScreenCallback
  translate: TranslateCallback
}) => (
  <Button
    type="button"
    variant="primary"
    className={classNames(theme['button-centered'], theme['button-lg'])}
    onClick={() => {
      trackScreen('take_selfie_button_clicked')
      nextStep()
    }}
    data-onfido-qa="selfie-continue-btn"
  >
    {translate('selfie_intro.button_primary')}
  </Button>
)

const Intro = ({
  translate,
  nextStep,
  trackScreen,
  steps,
  autoFocusOnInitialScreenTitle,
}: IntroProps) => {
  const instructions: Array<Instruction> = [
    {
      key: 'selfie',
      text: translate('selfie_intro.list_item_face_forward'),
    },
    {
      key: 'glasses',
      text: translate('selfie_intro.list_item_no_glasses'),
    },
  ]
  const actions = <Actions {...{ nextStep, trackScreen, translate }} />
  const isFirstScreen = steps[0].type === 'face'

  return (
    <ScreenLayout actions={actions} pageId={'SelfieIntro'}>
      <div className={theme.fullHeightContainer}>
        <PageTitle
          title={translate('selfie_intro.title')}
          subTitle={translate('selfie_intro.subtitle')}
          shouldAutoFocus={isFirstScreen && autoFocusOnInitialScreenTitle}
        />
        <InstructionsPure
          listScreenReaderText={translate('selfie_intro.list_accessibility')}
          instructions={instructions}
        />
      </div>
    </ScreenLayout>
  )
}

export default appendToTracking(
  trackComponent(localised(withCrossDeviceWhenNoCamera(Intro))),
  'selfie_intro'
) as ComponentType<StepComponentProps>
