import type { HttpRequestParams } from '../types'

const allowedIntegrationNames = [
  'smart-capture-link-generic',
  'smart-capture-link-unique',
  'standard',
]

const allowedIntegrationWrappers = ['web-view', 'iframe']

export default (httpRequestData: HttpRequestParams) => {
  if (!httpRequestData.headers) {
    httpRequestData.headers = {}
  }

  httpRequestData.headers['x-onfido-sdk-platform'] = 'web'
  httpRequestData.headers['x-onfido-sdk-version'] = process.env.SDK_VERSION!

  const metadata: Record<string, unknown> = {}

  // Used by SDK wrappers
  // @ts-ignore window.OnfidoIntegration is only for internal use
  const integration = window?.OnfidoIntegration
  if (integration && allowedIntegrationNames.indexOf(integration?.name) > -1) {
    metadata.integration = integration.name
    metadata['integration-version'] = integration.version
    if (
      integration?.wrapper &&
      allowedIntegrationWrappers.indexOf(integration?.wrapper) > -1
    ) {
      metadata['integration-wrapper'] = integration.wrapper
    }
  }

  if (Object.keys(metadata).length) {
    httpRequestData.headers['x-onfido-sdk-metadata'] = JSON.stringify(metadata)
  }

  return httpRequestData
}
