import { FunctionComponent, h } from 'preact'
import { useEffect, useRef } from 'preact/compat'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import style from './style.scss'

import type { RootState } from '~types/redux'

type Props = {
  className?: string
  smaller?: boolean
  subTitle?: string | JSX.Element
  title: string
  shouldAutoFocus?: boolean
}

const PageTitle: FunctionComponent<Props> = ({
  title,
  subTitle,
  smaller,
  className,
  shouldAutoFocus = true,
}) => {
  const isFullScreen = useSelector<RootState, boolean | undefined>(
    (state) => state.globals.isFullScreen
  )
  const containerRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    containerRef.current && shouldAutoFocus && containerRef.current.focus()
  }, [title, subTitle, shouldAutoFocus])

  return (
    <div
      className={classNames(
        style.titleWrapper,
        {
          [style.smaller]: smaller && !isFullScreen,
          [style.fullScreen]: isFullScreen,
        },
        className
      )}
    >
      <h1
        className={style.title}
        aria-live="assertive"
        ref={containerRef}
        tabIndex={-1}
      >
        {title}
      </h1>
      {subTitle && <h2 className={style.subTitle}>{subTitle}</h2>}
    </div>
  )
}

export default PageTitle
