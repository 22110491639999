import { parseJwt } from '~utils/jwt'

const CAPTURE_MAC_KEY = process.env.CAPTURE_MAC_KEY!

type SignatureResult = {
  signature: string
  nonce: string
}

const base64ToArrayBuffer = (str: string) => {
  const binary_string = window.atob(str)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

const arrayBufferToBase64 = (buffer: ArrayBufferLike) => {
  const bytes = new Uint8Array(buffer)
  const binary = [...bytes].map((c) => String.fromCharCode(c)).join('')
  return window.btoa(binary)
}

const computeClientNonce = (applicantId: string): string => {
  const currentTime = Math.floor(Date.now() / 1000)
  return applicantId + currentTime.toString()
}

const computeMacKey = async (applicantId: string): Promise<CryptoKey> => {
  const rawMacKeyBuffer = base64ToArrayBuffer(CAPTURE_MAC_KEY)
  const applicantIdBuffer = new TextEncoder().encode(applicantId)

  const macKeyBuffer = new Uint8Array(
    rawMacKeyBuffer.byteLength + applicantIdBuffer.byteLength
  )
  macKeyBuffer.set(new Uint8Array(applicantIdBuffer), 0)
  macKeyBuffer.set(
    new Uint8Array(rawMacKeyBuffer),
    applicantIdBuffer.byteLength
  )

  return window.crypto.subtle.importKey(
    'raw',
    macKeyBuffer,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  )
}

const computeSignature = async (
  data: ArrayBufferLike,
  nonce: string,
  macKey: CryptoKey
): Promise<ArrayBuffer> => {
  const nonceBuffer = new TextEncoder().encode(nonce)
  const payloadToSign = new Uint8Array(data.byteLength + nonceBuffer.byteLength)

  payloadToSign.set(new Uint8Array(data), 0)
  payloadToSign.set(nonceBuffer, data.byteLength)

  return window.crypto.subtle.sign('HMAC', macKey, payloadToSign)
}

/**
 * Signs a binary payload using the ETSI-compliant signature generation algorithm.
 * @param data Data to sign (binary)
 * @param token JWT
 * @returns A `SignatureResult` object containing a base-64 encoded `signature` and a `nonce`
 */
export const sign = async (
  data: ArrayBufferLike,
  token: string
): Promise<SignatureResult> => {
  const jwt = parseJwt(token)
  const applicantId = jwt.payload.app!

  const macKey = await computeMacKey(applicantId)
  const nonce = computeClientNonce(applicantId)
  const signature = await computeSignature(data, nonce, macKey)

  return {
    signature: arrayBufferToBase64(signature),
    nonce,
  }
}
