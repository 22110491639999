import { Component, createRef, h } from 'preact'
import classNames from 'classnames'
import { withFullScreenState, WithFullScreenStateProps } from '../FullScreen'
import { isDesktop } from '~utils'
import { localised } from '~core/localisation'
import style from './style.scss'
import type { WithLocalisedProps } from '~core/localisation/types'

type NavProps = {
  id?: string
  back?: () => void
  disabled?: boolean
  transparent?: boolean
  className?: string
}

type Props = NavProps & WithLocalisedProps & WithFullScreenStateProps

class NavigationBar extends Component<Props> {
  private backBtn = createRef<HTMLButtonElement>()

  componentDidUpdate(prevProps: Props) {
    const hasIdChanged = prevProps.id !== this.props.id
    if (this.backBtn.current && hasIdChanged) {
      this.backBtn.current.focus()
    }
  }

  render() {
    const {
      back,
      translate,
      disabled,
      isFullScreen,
      transparent,
      className,
    } = this.props
    return (
      <div
        className={classNames(className, style.navigation, {
          [style.transparent]: isFullScreen || transparent,
        })}
      >
        <button
          type="button"
          aria-label={translate('generic.back')}
          ref={this.backBtn}
          onClick={back}
          className={classNames(style.back, {
            [style.disabled]: disabled,
            [style.backHoverDesktop]: isDesktop,
          })}
        >
          <span className={style.iconBack} />
          <span className={style.label} aria-hidden="true">
            {translate('generic.back')}
          </span>
        </button>
      </div>
    )
  }
}

export default withFullScreenState(localised(NavigationBar))
