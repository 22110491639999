import { TranslateCallback } from '~core/localisation/types'
import { ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES } from '../IdNumberTypes'

type IdType = {
  name: string
  type: string
}

export const hasIdTypeSelector = (country: string) => {
  return getSupportedIdTypesList(country).length > 1
}

const getSupportedIdTypesList = (country: string) => {
  return Object.keys(ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES[country]) || []
}

const getIdType = (
  country: string,
  idType: string,
  translate: TranslateCallback
) => {
  const idName =
    translate(
      `profile_data.components.id_type_select.${country.toLocaleLowerCase()}_specific.${idType}`
    ) || translate(`profile_data.components.id_type_select.${idType}`)
  return {
    name: idName,
    type: idType,
  }
}

export const getIdTypesList = (
  country: string,
  translate: TranslateCallback
) => {
  const IdTypesList: IdType[] = []

  const supportedIdTypesList = getSupportedIdTypesList(country)
  if (supportedIdTypesList && supportedIdTypesList.length > 1) {
    supportedIdTypesList.forEach((idType) =>
      IdTypesList.push(getIdType(country, idType, translate))
    )
  }

  return IdTypesList
}
