export const SET_CURRENT_STEP_TYPE = 'SET_CURRENT_STEP_TYPE'
export const SET_GENERIC_DOCUMENT_TYPE = 'SET_GENERIC_DOCUMENT_TYPE'
export const SET_ID_DOCUMENT_TYPE = 'SET_ID_DOCUMENT_TYPE'
export const RESET_ID_DOCUMENT_TYPE = 'RESET_ID_DOCUMENT_TYPE'
export const SET_ID_ISSUING_COUNTRY = 'SET_ID_ISSUING_COUNTRY'
export const RESET_ID_ISSUING_COUNTRY = 'RESET_ID_ISSUING_COUNTRY'
export const SET_POA_DOCUMENT_TYPE = 'SET_POA_DOCUMENT_TYPE'
export const SET_POA_DOCUMENT_COUNTRY = 'SET_POA_DOCUMENT_COUNTRY'
export const RESET_POA_DOCUMENT_COUNTRY = 'RESET_POA_DOCUMENT_COUNTRY'
export const SET_ROOM_ID = 'SET_ROOM_ID'
export const SET_SOCKET = 'SET_SOCKET'
export const SET_MOBILE_NUMBER = 'SET_MOBILE_NUMBER'
export const SET_CLIENT_SUCCESS = 'SET_CLIENT_SUCCESS'
export const MOBILE_CONNECTED = 'MOBILE_CONNECTED'
export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const SET_NAVIGATION_DISABLED = 'SET_NAVIGATION_DISABLED'
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN'
export const SET_DEVICE_HAS_CAMERA_SUPPORT = 'SET_DEVICE_HAS_CAMERA_SUPPORT'
export const SET_URLS = 'SET_URLS'
export const SET_CROSS_DEVICE_CLIENT_INTRO_PRODUCT_NAME =
  'SET_CROSS_DEVICE_CLIENT_INTRO_PRODUCT_NAME'
export const SET_CROSS_DEVICE_CLIENT_INTRO_PRODUCT_LOGO_SRC =
  'SET_CROSS_DEVICE_CLIENT_INTRO_PRODUCT_LOGO_SRC'
export const HIDE_ONFIDO_LOGO = 'HIDE_ONFIDO_LOGO'
export const SHOW_COBRANDING = 'SHOW_COBRANDING'
export const SHOW_LOGO_COBRANDING = 'SHOW_LOGO_COBRANDING'
export const SET_DECOUPLE_FROM_API = 'SET_DECOUPLE_FROM_API'
export const RETRY_FOR_IMAGE_QUALITY = 'RETRY_FOR_IMAGE_QUALITY'
export const RESET_IMAGE_QUALITY_RETRIES = 'RESET_IMAGE_QUALITY_RETRIES'
export const SET_ANALYTICS_SESSION_UUID = 'SET_ANALYTICS_SESSION_UUID'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_CURRENT_SCRIPT_HOSTNAME = 'SET_CURRENT_SCRIPT_HOSTNAME'
export const SET_APPLICANT_UUID = 'SET_APPLICANT_UUID'
export const SET_WORKFLOW_RUN_UUID = 'SET_WORKFLOW_RUN_UUID'
export const SET_ANONYMOUS_UUID = 'SET_ANONYMOUS_UUID'
export const SET_CLIENT_UUID = 'SET_CLIENT_UUID'
export const SET_IS_TRIAL = 'SET_IS_TRIAL'
export const SET_STEPS_CONFIG = 'SET_STEPS_CONFIG'
export const SET_IS_CROSS_DEVICE_CLIENT = 'SET_IS_CROSS_DEVICE_CLIENT'

export const CAPTURE_CREATE = 'CAPTURE_CREATE'
export const CAPTURE_DELETE = 'CAPTURE_DELETE'
export const SET_CAPTURE_METADATA = 'SET_CAPTURE_METADATA'
export const SET_SDK_CONFIGURATION = 'SET_SDK_CONFIGURATION'

export const RESET_STORE = 'RESET_STORE'
