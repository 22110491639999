import { h } from 'preact'
import { IconElement } from '~types/commons'

const IconCamera: IconElement = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      {...props}
    >
      <defs>
        <path id="a" d="M.03 0h26.59v26.592H.029z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12"
          fill="var(--osdk-color-icon)"
          mask="url(#b)"
        />
        <path
          d="m20.358 8.334-3.62 3.191a1.05 1.05 0 0 0 0 1.618l3.62 3.19v-8zM15 15.367c0 .749-.552 1.356-1.234 1.356H5.234c-.682 0-1.234-.607-1.234-1.356V8.08c0-.749.552-1.357 1.234-1.357h8.532c.682 0 1.234.608 1.234 1.357v7.287z"
          fill="#FEFEFE"
          mask="url(#b)"
        />
      </g>
    </svg>
  )
}

export default IconCamera
