import { useEffect } from 'preact/hooks'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { performanceBenchmark } from './performanceBenchmark'

let triggered = false
let duration: number | undefined

const usePerformanceBenchmark = (): boolean | undefined => {
  const { experimental_features } = useSdkConfigurationService()

  const shouldRunBenchmark =
    experimental_features?.performance_benchmark?.enabled ?? false

  useEffect(() => {
    if (shouldRunBenchmark && !triggered) {
      triggered = true
      performanceBenchmark().then((benchmarkDuration) => {
        duration = benchmarkDuration
      })
    }
  }, [shouldRunBenchmark])

  if (!shouldRunBenchmark) return true

  const threshold = experimental_features?.performance_benchmark?.threshold

  return duration !== undefined && threshold !== undefined
    ? duration <= threshold
    : undefined
}

export default usePerformanceBenchmark
