import { generatePayloadTimestamp } from '~core/Analytics/createAnalyticsPayload'
import { EnvironmentType, LogData, OutputInterface } from './types'

export type LoggerProps = {
  labels?: string[]
  outputs: OutputInterface[]
  environment?: EnvironmentType
}

export type logMethodProps = [
  message: string,
  metadata: unknown,
  file?: string,
  method?: string,
  line?: string
]

export class Logger {
  private labels: string[] = []
  private environment: EnvironmentType =
    (process.env.NODE_ENV as EnvironmentType) || 'production'
  private outputs: OutputInterface[] = []

  constructor(props: LoggerProps) {
    this.labels = props?.labels || this.labels
    this.outputs = props?.outputs || this.outputs
    this.environment = props?.environment || this.environment

    if (!this.outputs.length) {
      throw new Error('There are no outputs provided to the logger')
    }
  }

  private log = (data: LogData) => {
    Object.values(this.outputs || {}).forEach((output: OutputInterface) => {
      if (output?.write) {
        output.write(data, this.environment)
      }
    })
  }

  debug = (
    message: string,
    metadata?: Record<string, unknown>,
    file?: string,
    method?: string,
    line?: string
  ) => {
    this.log({
      timestamp: generatePayloadTimestamp(),
      level: 'debug',
      labels: this.labels,
      message,
      metadata,
      file,
      method,
      line,
    })
  }

  info = (
    message: string,
    metadata?: Record<string, unknown>,
    file?: string,
    method?: string,
    line?: string
  ) => {
    this.log({
      timestamp: generatePayloadTimestamp(),
      level: 'info',
      labels: this.labels,
      message,
      metadata,
      file,
      method,
      line,
    })
  }

  warning = (
    message: string,
    metadata?: Record<string, unknown>,
    file?: string,
    method?: string,
    line?: string
  ) => {
    this.log({
      timestamp: generatePayloadTimestamp(),
      level: 'warning',
      labels: this.labels,
      message,
      metadata,
      file,
      method,
      line,
    })
  }

  error = (
    message: string,
    metadata?: Record<string, unknown>,
    file?: string,
    method?: string,
    line?: string
  ) => {
    this.log({
      timestamp: generatePayloadTimestamp(),
      level: 'error',
      labels: this.labels,
      message,
      metadata,
      file,
      method,
      line,
    })
  }

  fatal = (
    message: string,
    metadata?: Record<string, unknown>,
    file?: string,
    method?: string,
    line?: string
  ) => {
    this.log({
      timestamp: generatePayloadTimestamp(),
      level: 'fatal',
      labels: this.labels,
      message,
      metadata,
      file,
      method,
      line,
    })
  }
}
