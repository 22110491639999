import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/compat'
import classNames from 'classnames'
import { space } from '@onfido/castor'
import {
  Field,
  FieldLabel,
  HelperText,
  Validation,
  Input,
  Button,
} from '@onfido/castor-react'
import { useLocales } from '~core/localisation'
import ScreenLayout from '../Theme/ScreenLayout'
import PageTitle from '../PageTitle'
import theme from '../Theme/style.scss'
import { CountrySelector } from './CountrySelector'
import { StateSelector } from './StateSelector'
import { hasIdTypeSelector, IdTypeSelector } from './IdTypeSelector'
import { DateOfBirthInput, getMaxDay } from './DateOfBirthInput'
import { SSNInput } from './SSNInput'
import { useSdkOptions } from '~contexts'
import { isValidPhoneNumber } from 'react-phone-number-input'

import style from './style.scss'

import type { SdkOptions } from '~types/sdk'
import type { StepComponentDataProps, CompleteStepValue } from '~types/routers'
import type { Consents, StepOptionData } from '~types/steps'
import type {
  TranslateCallback,
  WithLocalisedProps,
} from '~core/localisation/types'
import { ProfileDataPhoneNumberInput } from '../PhoneNumberInput/Lazy'
import { allCountriesList } from './CountrySelector/countries'
import Spinner from '../Spinner'
import { Consent } from 'components/UserConsent/Consent'
import { ConsentTemplate, useConsents } from './useConsents'
import {
  IdNumberInput,
  hasValidNationalIdValue,
  hasIdNumberInput,
} from './IdNumberInput'
import { IdNumberTypes } from './IdNumberTypes'

export type ProfileDataProps = StepComponentDataProps & {
  title: string
  dataSubPath: string
  dataFields: string[]
  disabledFields: string[]
  profile_data_selection?: StepOptionData['profile_data_selection']
  ssnEnabled?: boolean
  panEnabled?: boolean
  nationalIdNumberEnabled?: boolean
  nextStep: () => void
  completeStep: (data: CompleteStepValue) => void
  consents?: Consents[]
} & WithLocalisedProps

const ProfileData = ({
  title,
  dataSubPath,
  dataFields,
  disabledFields,
  ssnEnabled,
  panEnabled,
  nationalIdNumberEnabled,
  getPersonalData,
  nextStep,
  completeStep,
  consents = [],
  company_name,
}: ProfileDataProps) => {
  const sdkOptions = useSdkOptions()
  const { translate } = useLocales()
  const { consentsData, consentsStatus, handleConsentChange } = useConsents(
    consents
  )
  const [formData, setFormData] = useState(() => {
    const personalData = getPersonalData?.() || {}
    const pathData = dataSubPath
      ? personalData[dataSubPath] ?? {}
      : personalData

    return {
      // make empty data values with fields described
      ...dataFields.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {}),
      // override values with already set personal data
      ...Object.fromEntries(
        Object.entries(pathData as Record<string, unknown>).filter(([key]) =>
          dataFields.includes(key)
        )
      ),
    }
  })

  // Touchers are a set of functions that can be executed to "touch" fields.
  // Upon touching a field, it can "invalidate" itself.
  // They also return a field validity state, so a form can check if fields
  // are valid at that moment.
  const [touchers, setTouchers] = useState<Array<Toucher>>([])

  const setToucher: SetToucherFunc = useCallback((type, toucher) => {
    setTouchers((t) => [...t, { type, toucher }])
  }, [])

  const removeToucher: RemoveRoucherFunc = useCallback((type) => {
    setTouchers((t) => t.filter((toucher) => toucher.type !== type))
  }, [])

  const handleChange: FieldValueChangeFunc = (type, value) => {
    if (type === 'country' || type === 'national_id_type') {
      updatePersonalData({ ...formData, [type]: `${value}` })
    }

    setFormData((fData) => ({ ...fData, [type]: `${value}` }))
  }

  const updatePersonalData = (data: object) => {
    const cleanedFormData = Object.fromEntries(
      // do not send empty values to API, instead remove those entries
      Object.entries(data).filter(([, value]) => value !== '')
    )

    const newPersonalData = Object.entries(cleanedFormData).reduce(
      (prev, [type, value]) => {
        if (type !== 'country_residence') return { ...prev, [type]: `${value}` }

        return {
          ...prev,
          [type]: `${value}`,
          address: {
            country: `${value}`,
          },
        }
      },
      {}
    )

    const consent = consentsData.reduce((prev, { granted, id }) => {
      return { ...prev, [`${id}_granted`]: granted }
    }, {})

    const stepData = { ...newPersonalData, ...consent }

    completeStep(
      dataSubPath
        ? {
            [dataSubPath]: stepData,
          }
        : stepData
    )
  }

  const handleSubmit = () => {
    let isFormValid = true

    touchers.forEach(({ toucher }) => {
      if (!toucher() && isFormValid) isFormValid = false
    })

    if (!isFormValid) return

    updatePersonalData(formData)
    nextStep()
  }

  if (consentsStatus === 'error') {
    return (
      <div data-page-id={'Error'}>
        <p>There was a server error!</p>
        <p>Please try reloading the app, and try again.</p>
      </div>
    )
  }

  if (consentsStatus !== 'done') {
    return <Spinner />
  }

  return (
    <ScreenLayout pageId={'ProfileData'}>
      <PageTitle title={translate(`profile_data.${title}`)} />
      <div className={style['form']}>
        {Object.entries(formData).map(([type, value]) => (
          <FieldComponent
            key={type}
            type={type as FieldType}
            data-onfido-qa={value}
            label={true}
            value={value as string}
            companyName={company_name}
            selectedCountry={
              (getPersonalData?.() as { address: { country: string } })?.address
                ?.country
            }
            sdkOptions={sdkOptions as SdkOptions}
            ssnEnabled={ssnEnabled}
            panEnabled={panEnabled}
            nationalIdNumberEnabled={nationalIdNumberEnabled}
            selectedIdType={
              (getPersonalData?.() as {
                national_id_number: {
                  national_id_type: IdNumberTypes | undefined
                }
              })?.national_id_number?.national_id_type
            }
            disabled={(disabledFields || []).includes(type)}
            setToucher={setToucher}
            removeToucher={removeToucher}
            onChange={handleChange}
          />
        ))}
        {consentsData.map(({ id, granted, ...consent }) => (
          <FieldComponent
            key={id}
            label={false}
            type={id}
            value={granted}
            companyName={company_name}
            selectedCountry={
              (getPersonalData?.() as { address: { country: string } })?.address
                ?.country
            }
            sdkOptions={sdkOptions as SdkOptions}
            consent={consent}
            disabled={false}
            setToucher={setToucher}
            removeToucher={removeToucher}
            onChange={(_, checked) => handleConsentChange(id, Boolean(checked))}
          />
        ))}
        <Button
          onClick={handleSubmit}
          data-onfido-qa="doneButton"
          className={classNames(
            theme['button-centered'],
            theme['button-lg'],
            style['submit-button']
          )}
        >
          {translate('profile_data.button_continue')}
        </Button>
      </div>
    </ScreenLayout>
  )
}

type FieldType =
  | 'first_name'
  | 'last_name'
  | 'dob'
  | 'ssn'
  | 'pan'
  | 'national_id_type'
  | 'national_id_value'
  | 'country'
  | 'country_residence'
  | 'nationality'
  | 'line1'
  | 'line2'
  | 'line3'
  | 'town'
  | 'state'
  | 'postcode'
  | 'email'
  | 'phone_number'

type ConsentType = Consents

type Toucher = {
  type: FieldComponentProps['type']
  toucher: ToucherFunc
}

type ToucherFunc = () => boolean
type SetToucherFunc = (
  type: FieldComponentProps['type'],
  toucher: ToucherFunc
) => void
type RemoveRoucherFunc = (type: FieldComponentProps['type']) => void

type FieldValueChangeFunc = (
  type: FieldValue['type'],
  value: FieldValue['value']
) => void

type FieldValue =
  | {
      type: FieldType
      value: string
    }
  | {
      type: ConsentType
      value: boolean
      consent: ConsentTemplate
    }

type FieldComponentProps = FieldValue & {
  sdkOptions: SdkOptions
  companyName?: string
  selectedCountry?: string
  label: boolean
  disabled: boolean
  ssnEnabled?: boolean
  panEnabled?: boolean
  nationalIdNumberEnabled?: boolean
  selectedIdType?: IdNumberTypes | undefined
  setToucher: SetToucherFunc
  removeToucher: RemoveRoucherFunc
  onChange: FieldValueChangeFunc
}

const FieldComponent = (props: FieldComponentProps) => {
  const {
    type,
    value,
    label,
    selectedCountry,
    companyName,
    ssnEnabled = false,
    panEnabled = false,
    nationalIdNumberEnabled = false,
    selectedIdType,
    sdkOptions,
    setToucher,
    removeToucher,
    onChange,
  } = props

  const { translate } = useLocales()

  const isRequired = isFieldRequired(
    type,
    selectedCountry,
    ssnEnabled,
    panEnabled,
    nationalIdNumberEnabled
  )
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<string | null>(null)

  const isInvalid = Boolean(validationError)

  useEffect(() => {
    setToucher(type, () => {
      setIsTouched(true)
      return !isInvalid
    })
    return () => {
      removeToucher(type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isInvalid])

  useEffect(() => {
    setValidationError(validateField(props)(translate))
  }, [props, translate])

  const handleBlur = () => {
    if (!isTouched) setIsTouched(true)
  }

  const handleChange = (ev: {
    target: { value: FieldValue['value'] }
  }): void => {
    onChange(type, ev.target.value)
  }

  const excludeNationalIdTypeSelector = () => {
    return (
      type === 'national_id_type' &&
      ((selectedCountry && !hasIdTypeSelector(selectedCountry)) ||
        (selectedCountry === 'IND' && panEnabled))
    )
  }

  const excludeNationalIdValue = () => {
    return (
      type === 'national_id_value' &&
      selectedCountry &&
      hasIdTypeSelector(selectedCountry) &&
      !selectedIdType
    )
  }

  // edge cases when field component should not be rendered at all
  if (
    (type === 'line3' && selectedCountry === 'USA') ||
    (type === 'state' && selectedCountry !== 'USA') ||
    (type === 'ssn' && (selectedCountry !== 'USA' || !ssnEnabled)) ||
    (type === 'pan' && (selectedCountry !== 'IND' || !panEnabled)) ||
    excludeNationalIdTypeSelector() ||
    excludeNationalIdValue() ||
    (type === 'ssn_consent' && selectedCountry !== 'USA')
  ) {
    if (value) onChange(type, '') // removed value if was already set
    return null
  }

  return (
    <Field className={style['field']}>
      {label && (
        <FieldLabel>
          <span>
            {getTranslatedFieldLabel(translate, type, selectedCountry)}
            {!isRequired && (
              <span className={style['optional']}>
                {` ${translate('profile_data.field_optional')}`}
              </span>
            )}
          </span>
          {getTranslatedFieldHelperText(translate, type, selectedCountry)}
        </FieldLabel>
      )}
      {getFieldComponent(
        sdkOptions,
        {
          ...props,
          invalid: isTouched && isInvalid,
          required: isRequired,
          onBlur: handleBlur,
          onChange: handleChange,
        },
        translate,
        selectedCountry,
        selectedIdType,
        companyName
      )}
      {isTouched && isInvalid && (
        <Validation state="error">{validationError}</Validation>
      )}
    </Field>
  )
}

const getFieldComponent = (
  sdkOptions: SdkOptions,
  props: FieldValue & {
    disabled: boolean
    invalid: boolean
    required: boolean
    onBlur: () => void
    onChange: (ev: { target: { value: FieldValue['value'] } }) => void
  },
  translate: TranslateCallback,
  country?: FieldComponentProps['selectedCountry'],
  idType?: FieldComponentProps['selectedIdType'],
  companyName?: string
) => {
  const { type, onChange } = props

  const smsNumberCountryCode = Array.isArray(sdkOptions)
    ? sdkOptions[0].smsNumberCountryCode
    : sdkOptions.smsNumberCountryCode

  const countryCode =
    allCountriesList.find((countryList) => countryList.isoAlpha3 === country)
      ?.countryCode ||
    smsNumberCountryCode ||
    'GB'

  switch (type) {
    case 'country_residence':
      return <CountrySelector {...props} />
    case 'country':
      return <CountrySelector {...props} />
    case 'nationality':
      return <CountrySelector {...props} />
    case 'state':
      return <StateSelector {...props} />
    case 'dob':
      return <DateOfBirthInput {...props} country={country} />
    case 'postcode':
      return (
        <Input
          {...props}
          onChange={(event) =>
            onChange({ target: { value: event.target.value.toUpperCase() } })
          }
          type="text"
          style={{ width: space(22) }}
        />
      )
    case 'email':
      return <Input {...props} type="email" />
    case 'phone_number':
      return (
        <ProfileDataPhoneNumberInput
          {...props}
          smsNumberCountryCode={countryCode}
          options={sdkOptions}
        />
      )
    case 'ssn':
      return (
        <SSNInput
          {...props}
          placeholder={
            translate('profile_data.components.ssn.placeholder') ||
            '123-45-6789'
          }
          style={{ width: space(22) }}
        />
      )
    case 'pan':
      return (
        <Input
          {...props}
          maxLength={10}
          pattern={'[a-zA-Z0-9-]+'}
          placeholder={
            translate('profile_data.components.pan.placeholder') || 'ABCDE1234F'
          }
          type="text"
        />
      )
    case 'national_id_type':
      return <IdTypeSelector {...props} country={country} />
    case 'national_id_value':
      return <IdNumberInput {...props} country={country} idType={idType} />
    case 'phone_number_consent':
      return (
        <Consent
          {...props}
          id={type}
          params={new Map()}
          onGrant={(grant) => onChange({ target: { value: grant } })}
        />
      )
    case 'ssn_consent':
      return (
        companyName && (
          <Consent
            {...props}
            id={type}
            expandable={false}
            params={
              new Map([
                ['days', '10'],
                ['client', companyName],
              ])
            }
            onGrant={(grant) => onChange({ target: { value: grant } })}
          />
        )
      )
    default:
      return <Input {...props} type="text" />
  }
}

const getTranslatedFieldLabel = (
  translate: TranslateCallback,
  type: FieldComponentProps['type'],
  country?: FieldComponentProps['selectedCountry']
) =>
  translate(
    `profile_data.${
      translateSpecific('label', type, country) || `field_labels.${type}`
    }`
  )

const getTranslatedFieldHelperText = (
  translate: TranslateCallback,
  type: FieldComponentProps['type'],
  country?: FieldComponentProps['selectedCountry']
) => {
  const specificTranslation = translateSpecific('helper_text', type, country)

  if (specificTranslation) {
    return (
      <HelperText>
        {translate(`profile_data.${specificTranslation}`)}
      </HelperText>
    )
  }

  switch (type) {
    case 'dob':
      return <HelperText>{getLocalisedDobFormatExample(country)}</HelperText>
    default:
      return null
  }
}

const getLocalisedDobFormatExample = (country: string | undefined) => {
  switch (country) {
    case 'USA':
      return 'MM / DD / YYYY'
    default:
      return 'DD / MM / YYYY'
  }
}

const isFieldRequired = (
  type: FieldComponentProps['type'],
  country?: FieldComponentProps['selectedCountry'],
  ssnEnabled?: FieldComponentProps['ssnEnabled'],
  panEnabled?: FieldComponentProps['panEnabled'],
  nationalIdNumberEnabled?: FieldComponentProps['nationalIdNumberEnabled']
): boolean => {
  const requiredFields = [
    'first_name',
    'last_name',
    'dob',
    'country',
    'country_residence',
    'line1',
    'postcode',
    'email',
    'phone_number',
    'nationality',
    'consent_phone_number',
    'consent_ssn',
  ]

  if (country === 'USA') {
    requiredFields.push('state')
    if (ssnEnabled) {
      requiredFields.push('ssn')
    }
  }

  if (country === 'IND' && panEnabled) {
    requiredFields.push('pan')
  }

  if (country === 'IND' && !panEnabled && nationalIdNumberEnabled) {
    requiredFields.push('national_id_type')
    requiredFields.push('national_id_value')
  }

  if (
    country &&
    country !== 'IND' &&
    hasIdNumberInput(country) &&
    nationalIdNumberEnabled
  ) {
    if (hasIdTypeSelector(country)) {
      requiredFields.push('national_id_type')
    }
    requiredFields.push('national_id_value')
  }

  return requiredFields.includes(type)
}

const hasValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const hasValidPostcode = (ssn: string) => {
  // taken from: https://stackoverflow.com/a/7259020
  return /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/.test(ssn)
}

const hasValidSsn = (ssn: string) => {
  return /^\d{3}-?\d{2}-?\d{4}$/.test(ssn)
}

const hasValidPan = (pan: string) => {
  return pan.length === 10 && /^\w+$/.test(String(pan).toLowerCase())
}

const validateField = ({
  type,
  value,
  selectedCountry,
  selectedIdType,
  ssnEnabled,
  panEnabled,
  nationalIdNumberEnabled,
}: FieldComponentProps) => (translate: TranslateCallback): string | null => {
  // consents granted
  if (type === 'phone_number_consent' && !value) {
    return translate(`profile_data.field_validation.required_consent`)
  }

  if (type === 'ssn_consent' && selectedCountry === 'USA' && !value) {
    return translate(`profile_data.field_validation.required_consent`)
  }
  // required values
  if (
    isFieldRequired(
      type,
      selectedCountry,
      ssnEnabled,
      panEnabled,
      nationalIdNumberEnabled
    ) &&
    !value
  ) {
    if (type === 'national_id_value' && nationalIdNumberEnabled) {
      return translateIdNumberValidation(
        translate,
        'required',
        selectedCountry,
        selectedIdType
      )
    }
    return translate(
      `profile_data.${
        translateSpecific('validation_required', type, selectedCountry) ||
        `field_validation.required_${type}`
      }`
    )
  }

  // invalid symbols/format
  if (
    (['first_name', 'last_name'].includes(type) &&
      /[\^!#$%*=<>;{}"]+/.test(value as string)) ||
    (['line1', 'line2', 'line3', 'town', 'postcode'].includes(type) &&
      /[\^!$%*=<>]+/.test(value as string))
  ) {
    return translate('profile_data.field_validation.invalid')
  }
  if (type === 'dob') {
    const [yyyy = '', mm = '', dd = ''] = value.split('-')

    const validity = {
      yyyy: true,
      mm: true,
      dd: true,
    }

    const parsedDd = parseInt(dd, 10)
    if (!parsedDd || parsedDd < 1 || parsedDd > getMaxDay(yyyy, mm)) {
      validity.dd = false
    }

    const parsedMm = parseInt(mm, 10)
    if (!parsedMm || parsedMm < 1 || parsedMm > 12) {
      validity.mm = false
    }

    const parsedYyyy = parseInt(yyyy, 10)
    if (
      !parsedYyyy ||
      parsedYyyy < 1900 ||
      parsedYyyy > new Date().getFullYear()
    ) {
      validity.yyyy = false
    }

    if (!Object.values(validity).every(Boolean)) {
      return translate('profile_data.field_validation.invalid_dob')
    }
  } else if (type === 'postcode' && selectedCountry === 'GBR') {
    if (!hasValidPostcode(value)) {
      return translate(
        'profile_data.field_validation.gbr_specific.invalid_postcode'
      )
    }
  } else if (type === 'ssn' && selectedCountry === 'USA' && ssnEnabled) {
    if (!hasValidSsn(value)) {
      return translate('profile_data.field_validation.usa_specific.invalid_ssn')
    }
  } else if (type === 'pan' && selectedCountry === 'IND' && panEnabled) {
    if (!hasValidPan(value)) {
      return translate('profile_data.field_validation.ind_specific.invalid_pan')
    }
  } else if (type === 'national_id_value' && selectedCountry && value) {
    if (!hasValidNationalIdValue(selectedCountry, selectedIdType, value)) {
      if (type === 'national_id_value' && nationalIdNumberEnabled) {
        return translateIdNumberValidation(
          translate,
          'invalid',
          selectedCountry,
          selectedIdType
        )
      }
    }
  } else if (type === 'phone_number') {
    return value && isValidPhoneNumber(value)
      ? ''
      : translate(`profile_data.field_validation.invalid_${type}`)
  } else if (type === 'email') {
    return hasValidEmail(value)
      ? ''
      : translate(`profile_data.field_validation.invalid_${type}`)
  }

  const valueByteLength = new TextEncoder().encode(value as string).length

  // value too short
  if (
    (type === 'last_name' && valueByteLength < 2) ||
    (type === 'postcode' &&
      selectedCountry &&
      ['GBP', 'USA'].includes(selectedCountry) &&
      valueByteLength < 5)
  ) {
    return translate(
      `profile_data.${
        translateSpecific('validation_too_short', type, selectedCountry) ||
        `field_validation.too_short_${type}`
      }`
    )
  }

  // value too long
  if (
    (type === 'first_name' && value.length > 32) ||
    (type === 'last_name' && value.length > 32) ||
    (type === 'line1' && selectedCountry === 'GBR' && value.length > 32) ||
    ((type === 'line2' || type === 'line3') && value.length > 255) ||
    (type === 'town' && valueByteLength >= 30) ||
    (type === 'postcode' &&
      (!selectedCountry || !['GBP', 'USA'].includes(selectedCountry)) &&
      valueByteLength > 15) ||
    (type === 'postcode' && selectedCountry === 'GBR' && valueByteLength > 8) ||
    (type === 'postcode' && selectedCountry === 'USA' && valueByteLength > 5)
  ) {
    return translate(
      `profile_data.${
        translateSpecific('validation_too_long', type, selectedCountry) ||
        `field_validation.too_long_${type}`
      }`
    )
  }

  return null
}

const translateSpecific = (
  translationType:
    | 'label'
    | 'helper_text'
    | 'validation_required'
    | 'validation_too_short'
    | 'validation_too_long',
  fieldType: FieldComponentProps['type'],
  country?: FieldComponentProps['selectedCountry']
): string | null => {
  switch (`${translationType}_${fieldType}_${country?.toLocaleLowerCase()}`) {
    case 'label_ssn_usa':
      return 'field_labels.usa_specific.ssn'
    case 'label_pan_ind':
      return 'field_labels.ind_specific.pan'
    case 'label_national_id_value_arg':
      return 'field_labels.arg_specific.national_id_value'
    case 'label_national_id_value_can':
      return 'field_labels.can_specific.national_id_value'
    case 'label_national_id_value_chn':
      return 'field_labels.chn_specific.national_id_value'
    case 'label_national_id_value_ita':
      return 'field_labels.ita_specific.national_id_value'
    case 'label_national_id_value_sgp':
      return 'field_labels.sgp_specific.national_id_value'
    case 'label_national_id_value_swe':
      return 'field_labels.swe_specific.national_id_value'
    case 'label_national_id_value_tur':
      return 'field_labels.tur_specific.national_id_value'
    case 'label_town_gbr':
      return 'field_labels.gbr_specific.town'
    case 'label_postcode_gbr':
      return 'field_labels.gbr_specific.postcode'
    case 'label_state_usa':
      return 'field_labels.usa_specific.state'
    case 'label_postcode_usa':
      return 'field_labels.usa_specific.postcode'
    case 'helper_text_line1_usa':
      return 'field_labels.usa_specific.line1_helper_text'
    case 'helper_text_line2_usa':
      return 'field_labels.usa_specific.line2_helper_text'
    case 'validation_required_ssn_usa':
      return 'field_validation.usa_specific.required_ssn'
    case 'validation_invalid_ssn_usa':
      return 'field_validation.usa_specific.invalid_ssn'
    case 'validation_required_postcode_gbr':
      return 'field_validation.gbr_specific.required_postcode'
    case 'validation_required_state_usa':
      return 'field_validation.usa_specific.required_state'
    case 'validation_required_postcode_usa':
      return 'field_validation.usa_specific.required_postcode'
    case 'validation_too_short_postcode_gbr':
      return 'field_validation.gbr_specific.too_short_postcode'
    case 'validation_too_short_postcode_usa':
      return 'field_validation.usa_specific.too_short_postcode'
    case 'validation_too_long_postcode_gbr':
      return 'field_validation.gbr_specific.too_long_postcode'
    case 'validation_too_long_postcode_usa':
      return 'field_validation.usa_specific.too_long_postcode'
    case 'validation_required_pan_ind':
      return 'field_validation.ind_specific.required_pan'
    case 'validation_invalid_pan_ind':
      return 'field_validation.ind_specific.invalid_pan'
  }

  return null
}

const translateIdNumberValidation = (
  translate: TranslateCallback,
  translationType: 'required' | 'invalid',
  country?: FieldComponentProps['selectedCountry'],
  idType?: FieldComponentProps['selectedIdType']
): string | null => {
  if (!country) {
    return null
  }
  const national_id_type = idType || 'national_id_value'
  return (
    translate(
      `profile_data.field_validation.${country.toLowerCase()}_specific.${translationType}_${national_id_type}`
    ) ||
    translate(
      `profile_data.field_validation.${translationType}_${national_id_type}`
    )
  )
}

export default ProfileData
