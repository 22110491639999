import { EventEmitter2 } from 'eventemitter2'

type Vendor = {
  hidden: string
  event: string
  state: string
}

const vendors: Vendor[] = [
  {
    hidden: 'hidden',
    event: 'visibilitychange',
    state: 'visibilityState',
  },
  {
    hidden: 'webkitHidden',
    event: 'webkitvisibilitychange',
    state: 'webkitVisibilityState',
  },
  {
    hidden: 'mozHidden',
    event: 'mozvisibilitychange',
    state: 'mozVisibilityState',
  },
  {
    hidden: 'msHidden',
    event: 'msvisibilitychange',
    state: 'msVisibilityState',
  },
  {
    hidden: 'oHidden',
    event: 'ovisibilitychange',
    state: 'oVisibilityState',
  },
]

class PageVisibility {
  private events = new EventEmitter2()
  private vendor?: Vendor

  constructor() {
    this.vendor = this.getVendor()
    this.listen()
  }

  private getVendor() {
    if (!this.isSupported()) return
    for (const event of vendors) {
      if (event.hidden in document) {
        return event
      }
    }
    return
  }

  public isSupported() {
    return typeof document !== 'undefined' && Boolean(document.addEventListener)
  }

  public isVisible() {
    if (!this.vendor) return null

    // @ts-ignore
    return !document[this.vendor.hidden]
  }

  public listen() {
    if (!this.vendor) return
    document.addEventListener(this.vendor.event, this.handler)
  }

  // TODO: unlisten automatically on Onfido.tearDown (now in ModalApp)
  public unlisten() {
    if (!this.vendor) return
    document.addEventListener(this.vendor.event, this.handler)
  }

  public unlistenAll = () => this.events.removeAllListeners('change')

  private handler = () => {
    this.events.emit('change', this.isVisible())
  }

  public addListener = (callback: (visible: boolean) => void) =>
    this.events.addListener('change', callback)

  public removeListener = (callback: (visible: boolean) => void) =>
    this.events.removeListener('change', callback)
}

export default new PageVisibility()
