import { h, Fragment, FunctionComponent } from 'preact'
import { Header } from '../Header'
import { ErrorIcon } from '../assets/ErrorIcon'
import { localised } from '~core/localisation'
import { trackComponent } from 'Tracker'
import { WithLocalisedProps } from '~core/localisation/types'
import type { WithTrackingProps } from '~types/hocs'
import { StepComponentProps } from '~types/routers'
import classNames from 'classnames'
import theme from '../../Theme/style.scss'
import { Button } from '@onfido/castor-react'
import ScreenLayout from 'components/Theme/ScreenLayout'
import styles from './style.module.scss'

type Props = StepComponentProps &
  WithTrackingProps &
  WithLocalisedProps & {
    restart: () => void
    retry: () => void
  }

const ConnectionError: FunctionComponent<Props> = ({
  restart,
  retry,
  trackScreen,
  translate,
}: Props) => {
  const actions = (
    <div className={styles.vertical}>
      <Button
        type="button"
        variant="primary"
        className={classNames(theme['button-centered'], theme['button-lg'])}
        onClick={() => {
          trackScreen('connection_error_retry_clicked')
          retry()
        }}
      >
        {translate('avc_connection_error.button_primary_retry_upload')}
      </Button>

      <Button
        type="button"
        variant="primary"
        className={classNames(theme['button-centered'], theme['button-lg'])}
        onClick={() => {
          trackScreen('connection_error_restart_clicked')
          restart()
        }}
      >
        {translate('avc_connection_error.button_secondary_restart_recording')}
      </Button>
    </div>
  )

  return (
    <ScreenLayout actions={actions} pageId={'ConnectionError'}>
      <Header title={translate('avc_connection_error.title')}>
        <ErrorIcon />
      </Header>

      {translate('avc_connection_error.subtitle')}
    </ScreenLayout>
  )
}

export default trackComponent(localised(ConnectionError), 'connection_error')
