import { Component } from 'preact'
import { captureException } from '~core/ExceptionHandler'

export class ErrorBoundary extends Component {
  state = {
    error: undefined,
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error.message }
  }

  componentDidCatch(error: Error) {
    captureException(error, undefined, '/src/core/ExceptionHandler/ErrorBoundary.tsx', 'ErrorBoundary.componentDidCatch', '19')
  }

  render() {
    return this.props.children
  }
}
