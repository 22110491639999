export const flushQueue = <T>(
  queue: T[],
  chunkSize = 1,
  callback: (chunks: T[]) => void
) => {
  for (let i = 0; i < queue.length; i += chunkSize) {
    const chunk = queue.slice(i, i + chunkSize)
    callback(chunk)
  }
}
