import { GenericDocumentType, DocumentTypes } from '~types/steps'

const doubleSidedDocs: DocumentTypes[] = [
  'driving_licence',
  'national_identity_card',
  'residence_permit',
]

const isDoubleSidedDocument = (
  documentType: DocumentTypes | undefined,
  genericDocumentType?: GenericDocumentType | undefined
) => {
  if (!documentType) {
    return false
  }

  if (documentType === 'generic_document' && genericDocumentType) {
    return genericDocumentType.pages > 1
  }

  return doubleSidedDocs.includes(documentType)
}

export { isDoubleSidedDocument }
