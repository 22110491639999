import { h } from 'preact'
import { Input } from '@onfido/castor-react'
import {
  DEFAULT_ID_NUMBER_DETAILS,
  hasIdNumber,
  IdNumberTypes,
  ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES,
} from '../IdNumberTypes'

export type IdNumberInputProps = {
  name?: string
  country?: string | undefined
  idType?: IdNumberTypes | undefined
  style?: { [key: string]: string | number }
  value?: number | string
  invalid?: boolean
  onChange?: (ev: { target: { value: string } }) => void
}

export const hasIdNumberInput = hasIdNumber

export const IdNumberInput = (props: IdNumberInputProps) => {
  const { example: placeholder, maxLength } = getIdNumberDetails(
    props.country,
    props.idType
  )
  return (
    <Input
      {...props}
      maxLength={maxLength}
      placeholder={placeholder}
      type="text"
    />
  )
}

const getIdNumberDetails = (
  country: string | undefined,
  idType: IdNumberTypes = IdNumberTypes.OTHER
) => {
  if (country && hasIdNumberInput(country)) {
    return (
      ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES[country][idType] ||
      DEFAULT_ID_NUMBER_DETAILS
    )
  }
  return DEFAULT_ID_NUMBER_DETAILS
}

export const hasValidNationalIdValue = (
  country: string,
  idType: IdNumberTypes = IdNumberTypes.OTHER,
  idValue: string
) => {
  const idNumberFormat = getIdNumberFormat(country, idType)
  if (!idNumberFormat) {
    return true
  }
  return idNumberFormat.test(idValue)
}

const getIdNumberFormat = (
  country: string,
  idType: IdNumberTypes = IdNumberTypes.OTHER
) => {
  if (hasIdNumberInput(country)) {
    return ID_NUMBER_TYPES_DETAILS_BY_COUNTRIES[country][idType]?.format
  }
}
